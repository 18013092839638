@import url("//db.onlinewebfonts.com/c/4da1e1dec59a2ee10d78ed81dd4e32ec?family=Riona + Sans");

$bgcolor: #ffffff;

body {
  margin: 0;
  padding: 0;
  font-family: "Riona Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $bgcolor;
  // background-image: url(./images/bg_circle.png);
  // background-repeat: no-repeat;
  // background-position: right top;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
