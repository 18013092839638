@import "./colors.scss";

#amount-icon {
    height: 28px;
    width: 28px;
    background-color: $primary_dark_green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.duration-picker {
    width: 225px;
    height: 45px;
}

.postcode-adornament {
    margin-right: 8px;
}


.label {
    display: flex;
    align-items: center;
    min-width: 270px;
    margin: 8px 0px;

    svg {
        font-size: 1.8rem;
        color: $primary_dark_green;
    }

    h6 {
        margin: 0px 4px;
    }

    .tooltip-label {
        display: flex;
        align-items: center;

        h6 {
            max-width: 170px;
        }
    }

}

.char-count {
    display: flex;
    justify-content: flex-end;
}

.field-container {
    background-color: $container_grey;
    padding: 16px;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiFormControl-root {
        min-width: 220px;
    }


    .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
        margin: 0px;
    }

    .pair-fields {
        display: flex;
        align-items: center;

        .date-pickers {
            display: flex;
            flex-direction: column;

            .MuiFormControl-marginNormal {
                margin-bottom: 0px;
            }

            .MuiTypography-caption {
                margin-top: 0;
                margin-left: 16px;
            }

            .infinite-deadline {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .MuiCheckbox-root {
                    padding: 0px;
                    padding-right: 8px;
                }

                .infinite-desc {
                    display: flex;
                    align-items: center;

                    h6 {
                        color: $primary_blue;
                    }
                }
            }
        }
    }

}