@import "./colors.scss";

.login-container {
    margin: 8px;

    h5 {
        color: white;
        font-weight: bold;
    }

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .MuiTypography-subtitle1 {
        color: white;
    }

    .MuiTextField-root {
        background-color: white;

        .MuiFilledInput-adornedEnd {
            padding-right: 12px;

            .MuiIconButton-root {
                padding: 0px;
            }
        }

        input {
            padding: 14px 8px;
        }
    }

    .MuiButton-root {
        background-color: $primary_yellow;
        border-radius: 40;
        padding: 6px 30px;
    }
}