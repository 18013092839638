@import "./colors.scss";


.task-particulars {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pill {
    display: flex;
    align-items: center;
    background-color: $pill_blue;
    padding: 16px;
    margin: 16px 0px;
    border-radius: 20px;
    width: 240px;

    .MuiSvgIcon-root {
        color: $primary_dark_green;
        margin-right: 8px;
    }
}

.total-budget {
    background-color: $secondary_grey;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    justify-content: space-between;

    h6 {
        font-weight: bold;
    }
}

.section-buttons {
    padding: 8px 0px;

    .MuiButton-root {
        margin: 0px 8px;
    }
}



.section-dialog {

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        min-width: 600px;

        h6 {
            font-weight: bold;
        }
    }

    .section-tile-container {

        .section-tile {
            display: flex;
            align-items: center;
        }
    }

    .import-section-action {
        display: flex;
        padding: 16px 8px;
        align-items: center;
        justify-content: space-between;
    }


}

.section-card-content {
    .select-all-box {
        display: flex;
        align-items: center;
        border: 1px solid $secondary_grey;
        width: 120px;
        cursor: pointer;
    }

    .section-lists {
        .section-item {
            display: flex;
            justify-content: space-between;

            .section-title {
                display: flex;
                align-items: center;

                .MuiListItemIcon-root {
                    min-width: 30px;
                }

                h6 {
                    font-weight: bold;
                }
            }

            .section-actions {
                visibility: hidden;
                display: flex;
                align-items: center;

                .edit-btn {
                    color: $primary_blue;
                }
            }
        }

        .section-item:hover {
            .section-actions {
                visibility: visible;
            }
        }

    }

    .view-all-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -10px;

        button {
            color: $primary_blue;
        }
    }
}

.graph-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0px 1px 2px grey;
    margin: 16px 0px;
    border-radius: 4px;

    .chart {
        width: 250px;
        margin: 16px 0px;

        .c3-legend-item {
            fill: $primary_dark_green;
        }
    }
}

.response-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary_main_green;
    padding: 16px;
    margin: 16px 0px;
    border-radius: 20px;
}