$primary_main_green: #4FA873;
$primary_dark_green: #15333B;

$primary_grey: #443F3F;
$secondary_grey: #C4C4C4;
$container_grey: #F5F5F5;
$draggable-item-grey: #E7E7E7;
$droppable-container-grey: #E5E5E5;

$primary_yellow: #B6A933;
$primary_blue: #006096;
$primary_red: #E31A3E;

$pill_blue: #E4F0F2;
$textfield_grey:#DFDFDF;
$add_image_grey: #A7ABAA;