@import "./colors.scss";

.task-card-content {
    .select-all-box {
        display: flex;
        align-items: center;
        border: 1px solid $secondary_grey;
        width: 120px;
        cursor: pointer;
    }


    .task-item {

        .task-container {
            display: flex;
            align-items: center;
            width: inherit;

            .task-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: inherit;
                cursor: pointer;

                .task-details {
                    display: flex;
                    align-items: center;


                    h6 {
                        font-weight: bold;
                    }

                    .task-particulars {
                        display: flex;
                        justify-content: flex-start;

                        div {
                            display: flex;
                            margin: 0px 16px;

                            svg {
                                margin-right: 8px;
                                color: $primary_dark_green;
                            }
                        }
                    }

                    .MuiChip-root {
                        margin-top: 8px;
                    }
                }

                .task-action {
                    display: flex;
                    align-items: center;

                    h6 {
                        font-weight: bold;
                    }

                    .buttons {
                        visibility: hidden;

                        .delete-btn {
                            color: $primary_red;
                        }
                    }
                }
            }

            .task-section:hover {
                .task-action {
                    .buttons {
                        visibility: visible;
                    }
                }
            }
        }
    }
}