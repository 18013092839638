@import "./colors.scss";

$medium: 960px;

@media (min-width: $medium) {
    .section-builder-container {
        display: flex;

        .draggable {
            width: 380px;
            height: fit-content;
            position: -webkit-sticky;
            position: sticky;
            top: 110px;


            .draggable-items-container {
                margin-top: 4px;

                .dg-item {
                    margin: 2px 0px !important;
                    width: 280px !important;
                }
            }
        }

        .droppable {
            width: 100%;
        }
    }
}

.section-builder-container {

    .draggable {
        margin: 8px 16px;
        background-color: $primary-dark-green;

        .MuiList-root {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .dnd-item~[data-rbd-placeholder-context-id] {
            display: none !important;
        }

        .dnd-item~div {
            transform: none !important;
        }

        .draggable-title {
            padding: 16px;
            border-bottom: 4px solid $primary-yellow;

            h5 {
                font-weight: 500;
                text-align: center;
            }
        }

        .draggable-items-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .dg-item {
                background-color: $draggable-item-grey;
                margin: 8px;
                padding: 16px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 240px;

                h4 {
                    margin: 0px 8px;
                    font-size: 18px;
                }

                div {
                    display: flex;
                    align-items: center;
                }

                svg {
                    font-size: 2.0rem;
                    color: $primary_dark_green;
                    margin: 0px;
                }
            }

            .dg-item:hover {
                cursor: move;
            }
        }
    }

    .droppable {
        .form-container {
            margin: 8px 16px;
            padding: 16px;
            background-color: $droppable-container-grey;
            border-radius: 20px;
            height: fit-content;


            .fields-container {
                display: flex;
                background-color: white;
                padding: 16px;
                margin-top: 8px;
                border-radius: 10px 10px 0px 0px;
                flex-direction: column;

                svg {
                    font-size: 2.0rem;
                    color: $primary_dark_green;
                    margin: 0px 8px;
                }

                .default-field {
                    display: flex;
                    align-items: center;
                    width: -webkit-fill-available;
                }
            }

            .filled-drop-list {
                padding: 0px;
                margin-top: 8px;
                margin-bottom: 0px;
                list-style-type: none;
                box-shadow: 4px 7px 5px rgba(0, 0, 0, 0.1);
            }

            .empty-drop-list {
                padding: 32px;
                margin-top: 8px;
                margin-bottom: 0px;
                list-style-type: none;
                border: 1px solid;
                border-radius: 20px;
                box-shadow: 4px 7px 5px rgba(0, 0, 0, 0.1);

                #drop-here-hint {
                    text-align: center;
                }
            }

            .empty-drop-list:hover {
                #drop-here-hint {
                    opacity: 0.4;
                }
            }
        }

        .form-action-container {
            margin: 8px 16px;
            padding: 16px;
        }

    }
}

.envelop-card {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    background-color: white;
    margin: 1px;

    .question-container {
        display: flex;
        flex-grow: 1;

        .question-fields {
            flex-grow: 1;
        }


        .item-icon-style {
            margin-right: 16px;
            margin-top: 38px;

            svg {
                color: $primary-dark-green;
                font-size: 2.0rem;
            }
        }

        .add-image {

            display: flex;
            align-items: center;

            input {
                display: none;
            }

            .MuiButton-text {
                margin-top: 8px;
                color: $add_image_grey;
                padding: 0px;
            }

            .tool-tip {
                margin-top: 10px;
                margin-left: 8px;
            }
        }

        .required-switch {
            width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                display: flex;
                align-items: center;

                svg {
                    margin: 0px 8px;
                }
            }
        }

        .options-container {

            .grid-container {
                display: flex;
                justify-content: space-between;
                padding-top: 16px;

                .row-options {
                    flex-grow: 1;
                    margin-right: 8px;
                }

                .column-options {
                    flex-grow: 1;
                    margin-left: 8px;
                }
            }

            .value-container {
                display: flex;

                .field {
                    display: flex;
                    flex-grow: 1;
                    margin: 8px 0px;

                    svg {
                        margin-right: 8px;
                        color: $primary-dark-green;
                    }
                }
            }

            .value-container:hover .value-action {
                visibility: visible;
            }

            .value-action {
                display: flex;
                visibility: hidden;

                .MuiIconButton-root {
                    color: $primary-red;

                }
            }
        }


        .image-count-field {
            width: 300px;
            margin-top: 8px;
            display: flex;
            justify-content: space-between;

            div:first-child {
                display: flex;
                align-items: center;

                svg {
                    margin-left: 8px;
                    font-size: 2.0rem;
                }
            }
        }

        .slider-options {
            display: flex;
            margin: 8px 0px;

            div {
                display: flex;
                align-items: center;
                width: 250px;

                svg {
                    margin-left: 8px;
                }
            }
        }
    }

    .envelop-options {
        visibility: hidden;
    }


}

.envelop-card:hover {
    border: 2px solid $primary-dark-green;

    .envelop-options {
        visibility: visible;
    }
}

.section-preview {
    width: 600px;

    .title {
        font-weight: bold;
    }

    .container {
        width: 100%;
        margin: 8px 0px;

        .MuiFormControl-root {
            width: 100%;
            margin: 4px 0px
        }

        .options-preview {
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }

        .image-preview {
            display: flex;
            align-items: center;

            svg {
                font-size: 6rem;
                margin-left: -10px;
            }
        }

        .slider-preview {
            display: flex;
            align-items: center;

            .range {
                h6 {
                    margin: 0px 8px;
                }

                display: flex;
                width: 500px;
                margin-right: 16px;
            }
        }

        .grid-preview {
            display: flex;
            justify-content: space-between;

            div {
                width: 250px;
            }
        }
    }
}