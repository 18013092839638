@import "./colors.scss";
@import "./create-task.scss";
@import "./task-details.scss";
@import "./section-builder.scss";
@import "./login.scss";
@import "./task-list.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.required {
    color: $primary_red;
}

.MuiTextField-root {
    min-width: 220px;

    .MuiFilledInput-inputMarginDense,
    .MuiFilledInput-multiline.MuiFilledInput-marginDense,
    .MuiFilledInput-multiline {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.MuiCardContent-root:last-child {
    padding: 16px !important;
}

#tooltip-icon {
    font-size: 1.4rem;
    color: $primary_dark_green;
}

.page-bar {
    background-color: $primary_main_green;
    padding: 16px;

    h5 {
        font-weight: 600;
    }

    border-radius: 10px 10px 0px 0px;
}

.rich-text-editor {
    background-color: $textfield_grey !important;

    .EditorToolbar__root___3_Aqz {
        border-bottom: 1px solid $primary_dark_green;
    }
}